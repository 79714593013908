import { FC, createContext, useContext, useState } from 'react';
import { ClientsServices } from 'src/services/clients';
import { IClients, IClientsContext, IHookProvider } from 'src/types';

/*
Creates a new context using a given interface as type
*/
const ClientsContext = createContext<IClientsContext>({} as IClientsContext);

/**
Applies state and CRUD functionalities for client objects,
providing them to child components
@provider
*/
export const ClientsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const clientServices = new ClientsServices();

	const [entities, setClients] = useState<IClients[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchEntity, setSearchEntity] = useState<string>('');
	const [entitiesPerRow, setEntitiesPerRow] = useState<string>('8');
	const [paginate, setPaginate] = useState<number>(0);

	const createNewEntity = async (_client: IClients) => {
		try {
			const client = await clientServices.createEntity(_client);

			fetchEntities();
			return client;
		} catch (_err) {
			throw _err;
		}
	};

	const editEntity = async (_id: string, _client: IClients) => {
		try {
			const client = await clientServices.updateEntity(_id, _client);

			fetchEntities();
			return client;
		} catch (_err) {
			throw _err;
		}
	};

	const deleteEntity = async (_id: string) => {
		try {
			const client = await clientServices.deleteEntity(_id);

			fetchEntities();
			return client;
		} catch (_err) {
			throw _err;
		}
	};

	const fetchEntities = async (_query?: string) => {
		let clients: IClients[] = [];
		setLoading(true);
		try {
			clients = await clientServices.getEntities(_query);
		} catch (_err) {
			console.log(_err);
		} finally {
			setClients([...clients]);
			setLoading(false);
			return clients;
		}
	};

	const fetchEntity = async (_id: string) => {
		const client: IClients = await clientServices.getEntity(_id);
		return client;
	};

	return (
		<ClientsContext.Provider
			value={{
				entities,
				loading,
				fetchEntities,
				fetchEntity,
				createNewEntity,
				editEntity,
				deleteEntity,

				searchEntity,
				setSearchEntity,
				entitiesPerRow,
				setEntitiesPerRow,
				paginate,
				setPaginate,
			}}
		>
			{_params.children}
		</ClientsContext.Provider>
	);
};

/*
Calls current context to be used in child component
*/
export function useClients() {
	const context = useContext(ClientsContext);

	if (!context) {
		throw new Error('useClients must be used within an ClientsProvider');
	}

	return context;
}
