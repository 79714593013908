import { FC } from "react";

import { AnalysesProvider } from "./analyses";
import { ClientsProvider } from "./clients";
import { CommentsProvider } from "./comments";
import { DevicesProvider } from "./devices";
import { ImagesProvider } from "./images";
import { InspectionsProvider } from "./inspections";
import { OrdersProvider } from "./ordersOfService";
import { RolesProvider } from "./roles";
import { RoutesProvider } from "./routes";
import { TagsProvider } from "./tags";
import { UsersProvider } from "./users";
import { IHookProvider } from "../types";

/*
Applies route related state and functionalities for to its children
*/
export const AppProvider: FC<IHookProvider> = (params: IHookProvider) => {
  return (
    <RoutesProvider>
      <UsersProvider>
        <RolesProvider>
          <ClientsProvider>
            <OrdersProvider>
              <TagsProvider>
                <AnalysesProvider>
                  <InspectionsProvider>
                    <CommentsProvider>
                      <ImagesProvider>
                        <DevicesProvider>{params.children}</DevicesProvider>
                      </ImagesProvider>
                    </CommentsProvider>
                  </InspectionsProvider>
                </AnalysesProvider>
              </TagsProvider>
            </OrdersProvider>
          </ClientsProvider>
        </RolesProvider>
      </UsersProvider>
    </RoutesProvider>
  );
};
