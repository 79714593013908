import { AxiosResponse } from "axios";

import api from ".";
import { EntityServices } from "./entities";
import { ITags } from "src/types";

export class TagsServices extends EntityServices<ITags> {

    constructor () {
        const _baseUrl: string = "/tags";
        super("Etiqueta", _baseUrl)
    }

    async createEntity (_payload: ITags, clientId?: string): Promise<ITags> {
        const response: AxiosResponse = await api.post(`clients/${clientId}${this.baseUrl}`, _payload);

        if (response.data && response?.request?.status === 200) {
            let entity: ITags = response.data
            
            if (!entity.id) throw new Error(`Erro ao criar um(a) ${this.entityForLog}.`);
            return entity;

        } else if (response.data && response?.request?.status === 400) {
            throw new Error(response.data as string);
        }
        throw new Error(`Não foi possível criar um(a) ${this.entityForLog}.`);
    }

    async updateEntity (_id: string, _payload: ITags, clientId?: string): Promise<ITags> {
        const response: AxiosResponse = await api.put(`clients/${clientId}${this.baseUrl}/${_id}`, _payload
        );

        if (response.data && response?.request?.status === 200) {
            return response.data
        }
        throw new Error(`Não foi possível editar um(a) ${this.entityForLog}.`);
    }

    async deleteEntity (_id: string, clientId?: string): Promise<ITags> {
        const response: AxiosResponse = await api.delete(`clients/${clientId}${this.baseUrl}/${_id}`);

        if (response.data && response?.request?.status === 200) {
            return response.data

        } else if (response.data && response?.request?.status === 400) {
            throw new Error(response.data as string);
        }
        throw new Error(`Não foi possível remover um(a) ${this.entityForLog}.`);
    }

    async getEntity (_id: string, clientId?: string): Promise<ITags> {
        const response: AxiosResponse = await api.get(`clients/${clientId}${this.baseUrl}/${_id}`);

        if (response.data && response?.request?.status === 200) {
            return response.data
        }
        throw new Error(`Não foi possível buscar o(a) ${this.entityForLog}`);
    }

    async getEntities (_query?: string, clientId?: string): Promise<ITags[]> {
        const response: AxiosResponse = await api.get(`clients/${clientId}${this.baseUrl}${_query ? _query : ''}`);

        if (response.data && response?.request?.status === 200) {
            return response.data;
        }
        throw new Error(`Não foi possível buscar os(as) ${this.entityForLog}s`);
    }

}
