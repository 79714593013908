import { FC, useState, useEffect } from 'react';
import { useAnalyses } from 'src/hooks/analyses';

import { theme } from 'src/components';
import { Button } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { IConfirmAnalysisAction } from 'src/types';

const ConfirmAnalysisAction: FC<IConfirmAnalysisAction> = (params) => {
	const { editEntity } = useAnalyses();

	const [isConfirmed, setIsConfirmed] = useState<number>(params.analysis.isAiRight);

	const updateConfirmation = async (clickResult: number) => {
		params.analysis.isAiRight = clickResult;

		if (params.analysis) {
			await editEntity(params.analysis.id, params.analysis);
		}
	};

	const handleSteps = async (clickResult: number) => {
		if (clickResult === 1) {
			setIsConfirmed(isConfirmed === 1 ? 2 : 1);
			if (isConfirmed === 1) {
				setIsConfirmed(2);
				clickResult = 2;
			} else {
				setIsConfirmed(1);
				clickResult = 1;
			}
		} else {
			if (isConfirmed === 0) {
				setIsConfirmed(2);
				clickResult = 2;
			} else {
				setIsConfirmed(0);
				clickResult = 0;
			}
		}

		await updateConfirmation(clickResult);
	};

	useEffect(() => {
		handleSteps(params.analysis.isAiRight);
	}, [params.analysis]);

	return (
		<div className='confirm-ai-btn flex space-between mx-1' style={{ width: '102px' }}>
			<Button
				variant='contained'
				sx={{
					minWidth: '48px',
					maxWidth: '48px',
					minHeight: '48px',
					maxHeight: '48px',
				}}
				color={isConfirmed === 1 ? 'success' : 'inherit'}
				disableRipple
				onClick={() => {
					handleSteps(1);
				}}
			>
				<ThumbUpOutlinedIcon
					style={{ fontSize: '28px' }}
					htmlColor={
						isConfirmed === 1
							? theme.palette.background.default
							: theme.palette.backgroundElements.dark
					}
				/>
			</Button>

			<Button
				variant='contained'
				sx={{
					minWidth: '48px',
					maxWidth: '48px',
					minHeight: '48px',
					maxHeight: '48px',
				}}
				color={isConfirmed === 0 ? 'error' : 'inherit'}
				disableRipple
				onClick={() => {
					handleSteps(0);
				}}
			>
				<ThumbDownOutlinedIcon
					style={{ fontSize: '28px' }}
					htmlColor={
						isConfirmed === 0
							? theme.palette.background.default
							: theme.palette.backgroundElements.dark
					}
				/>
			</Button>
		</div>
	);
};

export default ConfirmAnalysisAction;
