const ptBr: any = {
    id: "ID",
    user: "Usuário",
    users: "Usuários",
    role: "Permissão",
    roles: "Permissões",
    access: "Permissões",
    client: "Cliente",
    clients: "Clientes",
    tag: "Etiqueta",
    tags: "Etiquetas",
    inspections: "Inspeções",
    anomalies: "Anomalias",
    images: "Imagens",
    name: "Nome",
    firstName: "Nome",
    lastName: "Sobrenome",
    email: "E-mail",
    username: "Usuário",
    password: "Senha",
    address: "Endereço",
    cnpj: "CNPJ",
    type: "Tipo",
    code: "Código",
    group: "Grupo",
    description: "Descrição",
    status: "Status",
    createdAt: "Criado em",
    createdTimestamp: "Criado em",
    updatedAt: "Atualizado em",
  };
  
  export function translateData(data: any) {
    return ptBr[data] ?? data;
  }
  