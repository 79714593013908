import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomePage: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/analysis", { replace: true });
  }, []);

  return <div />;
};

export default HomePage;
