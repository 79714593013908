import { AxiosResponse } from "axios";

import api from ".";
import { EntityServices } from "./entities";
import { IInspections } from "src/types";

export class InspectionsServices extends EntityServices<IInspections> {

  constructor () {
    const _baseUrl: string = "/inspections";
    super("Inspeção", _baseUrl)
  }

  async inspect (analysis_id: string): Promise<IInspections> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/inspect?analysis_id=${analysis_id}`);

    if (response?.request?.status === 200) {
        return response.data;
    }
    throw new Error(`Não foi possível inspecionar a ${this.entityForLog}`);
    }
}
