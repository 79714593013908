import { FC, useState, useEffect } from 'react';

import { useAuth } from 'src/hooks/auth';
import { useUsers } from 'src/hooks/users';
import { SideMenu } from 'src/components';
import ClientsManagement from './clients';
import UsersManagement from './users';
// import RolesManagement from './roles';
import TagsManagement from './tags';

// let pages = ['clients', 'users', 'roles', 'tags'];
let pages = ['clients', 'users', 'tags'];

const ManagementPage: FC = () => {
	const { user } = useAuth();
	const { fetchEntity } = useUsers();

	const [loggedUser, setLoggedUser] = useState<any>(null);
	const [selectedPage, setSelectedPage] = useState<string>(pages[0]);
	const [selectedRow, setSelectedRow] = useState<string>('');

	const getLoggerUser = async () => {
		if (user) {
			const tempUser: any = await fetchEntity(user.id);
			setLoggedUser(tempUser);
		}
	};

	useEffect(() => {
		getLoggerUser();
	}, []);

	useEffect(() => {
		getLoggerUser();
	}, [user]);

	return (
		<div className='flex container app-page'>
			<SideMenu
				items={loggedUser?.access[0] !== 'Administradores' ? ['clients', 'tags'] : pages}
				selectedItem={selectedPage}
				selectAction={setSelectedPage}
			/>

			{selectedPage === pages[0] && (
				<ClientsManagement
					user={user}
					setPage={setSelectedPage}
					setSelectedRow={setSelectedRow}
				/>
			)}
			{/* {selectedPage === pages[3] && ( */}
			{selectedPage === pages[2] && (
				<TagsManagement user={user} setPage={setSelectedPage} parent={selectedRow} />
			)}
			{selectedPage === pages[1] && loggedUser?.access[0] === 'Administradores' && (
				<UsersManagement user={user} setPage={setSelectedPage} />
			)}
			{/* {selectedPage === pages[2] && loggedUser?.access[0] === 'Administradores' && (
				<RolesManagement user={user} setPage={setSelectedPage} />
			)} */}
		</div>
	);
};

export default ManagementPage;
