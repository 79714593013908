import Keycloak from 'keycloak-js';
import { KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID } from '.'

let _keycloakClient: Keycloak | null = null;

const keycloakClientGenerator = () => {
    if (_keycloakClient) {
        return _keycloakClient;
    }

    _keycloakClient = new Keycloak({
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
    });

    return _keycloakClient;
}
export default keycloakClientGenerator;

const unloadKeycloak = () => {
    _keycloakClient = null;
}
export {unloadKeycloak};