import { FC, useState } from 'react';

import { theme } from 'src/components';
import { Button, Modal } from '@mui/material';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import { IImageUri } from 'src/types';
import { NO_IMAGE } from 'src/utils/consts';

const ImageZoomModalButton: FC<IImageUri> = (params: IImageUri) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div className='zoom-modal-btn'>
			<div>
				{params.image_path !== NO_IMAGE ? (
					<Button
						variant='contained'
						disableRipple
						sx={{
							minWidth: '48px',
							maxWidth: '64px',
							minHeight: '48px',
							maxHeight: '64px',
							padding: '0',
							borderRadius: '2px',
							borderColor: theme.palette.backgroundElements.main,
							backgroundColor: theme.palette.background.default,
							color: theme.palette.primary.main,
							'&:hover': { backgroundColor: theme.palette.background.default },
						}}
						onClick={handleOpen}
					>
						<ZoomOutMapOutlinedIcon style={{ fontSize: '32px' }} htmlColor='primary' />
					</Button>
				) : (
					<Button disabled variant='contained'>
						<ZoomOutMapOutlinedIcon />
					</Button>
				)}
			</div>

			<Modal open={open} onClose={handleClose}>
				<div className='flex column end m-1'>
					<Button
						variant='contained'
						disableRipple
						sx={{ borderRadius: '3px' }}
						onClick={handleClose}
					>
						<span className='m-0'>Voltar</span>
					</Button>

					<div className='container flex center-x img-modal of-hide'>
						<img
							src={params.image_path}
							alt='Frame capturada da anomalia detectada'
							height='100%'
							style={{ marginTop: '8px' }}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ImageZoomModalButton;
