import { EntityServices } from "./entities";
import { IClients } from "src/types";

export class ClientsServices extends EntityServices<IClients> {

  constructor () {
    const _baseUrl: string = "/clients";
    super("Cliente", _baseUrl)
  }
}
