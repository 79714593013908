import { FC, useEffect } from 'react';

import { useClients } from 'src/hooks/clients';
import { DataTable, InsertButton } from 'src/components';
import { IManagementPage } from 'src/types';
import { IClients } from 'src/types';

const clientTemplate: IClients = {
	id: '',
	name: '',
	cnpj: '',
	address: '',
};

const ClientsManagement: FC<IManagementPage> = (params) => {
	const { entities, fetchEntities, createNewEntity, editEntity, deleteEntity } = useClients();

	const getClients = async () => {
		await fetchEntities();
	};

	useEffect(() => {
		getClients();
	}, [params.user]);

	return (
		<div className='box-9 flex column px-2'>
			<InsertButton item={entities[0] ?? clientTemplate} service={createNewEntity} />
			<DataTable
				entities={entities}
				editEntity={editEntity}
				deleteEntity={deleteEntity}
				setPage={params.setPage}
				setSelectedRow={params.setSelectedRow}
			/>
		</div>
	);
};

export default ClientsManagement;
