import { EntityServices } from "./entities";
import { IDevices } from "src/types";

export class DevicesServices extends EntityServices<IDevices> {

  constructor () {
    const _baseUrl: string = "/devices";
    super("Dispositivo", _baseUrl)
  }
}
