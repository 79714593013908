import { FC } from 'react';
import { Button } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { useAuth } from 'src/hooks/auth';

const Logout: FC = () => {
	const { user, groups, logout } = useAuth();

	return (
		<div className='flex center-y' style={{ gap: '16px' }}>
			<div>
				<span style={{ display: 'block' }}> {user?.firstName + ' ' + user?.lastName} </span>
				<span className='bold primary'>{groups.length > 0 ? groups[0] : ''}</span>
			</div>

			<Button variant='text' sx={{ minWidth: 'fit-content', padding: 0 }} onClick={logout}>
				<LogoutOutlinedIcon style={{ fontSize: '28px' }} color='primary' />
			</Button>
		</div>
	);
};

export default Logout;
