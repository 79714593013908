import { EntityServices } from "./entities";
import { IRoute } from "../types";

export class RouteServices extends EntityServices<IRoute> {

  constructor () {
    const _baseUrl: string = "/api_user/routes";
    super("Rota", _baseUrl)
  }
}
