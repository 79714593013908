import { IReportFilterParams } from "src/types";
import no_image from "src/assets/_no_image.png";

export const getStatusColor = (status: string) => {
    switch (status) {
      case "A fazer":
        return "grey";
      case "Concluída c/ anomalias":
        return "red";
      case "Concluída s/ anomalias":
        return "green";
      case "Em análise":
        return "orange";
      default:
        return "grey";
    }
  };

export const blankFilterParams: IReportFilterParams = {
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    deviceNames: ["Todos"],
    clientsNames: ["Todos"],
    analysisIsAiRight: undefined,
    orderByDesc: false,
    orderByDateTime: false,
    orderById: false,
    orderByDetection: false,
    orderByTags: false,
    orderByClientName: false,
    orderByDeviceName: false,
};

export const KC_ACCESS_TOKEN: string = "KC_ACCESS_TOKEN";
export const KC_REFRESH_TOKEN: string = "KC_REFRESH_TOKEN";
export const KC_TOKEN_EXPIRES_IN: string = "KC_TOKEN_EXPIRES_IN";
export const KC_USER: string = "KC_USER";

export const NO_IMAGE: string = no_image;
