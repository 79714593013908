import { AxiosResponse } from "axios";
import api from ".";

import { EntityServices } from "./entities";
import { IUsers } from "src/types";

export class UsersServices extends EntityServices<IUsers> {

    constructor () {
        const _baseUrl: string = "/users";
        super("Usuário", _baseUrl)
    }

    async validate(_id: string, _object: string): Promise<boolean> {
        const response: AxiosResponse = await api.get(`${this.baseUrl}/${_id}/validate?${_object ? _object : ''}`);

        if (response.data && response?.request?.status === 200) {
            return response.data
        }
        throw new Error(`Não foi possível verificar se o(a) ${this.entityForLog} pode acessar essa funcionalidade`);
    }

}
