import { FC, useState } from "react";
import { Button, Modal } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

import { theme } from "src/components";
import { IUpdateDataButton } from "src/types";

const DeleteButton: FC<IUpdateDataButton> = (params) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteItem = async (item: any) => {
    let result = null;
    result = await params.service(item.id);

    if (result) {
      handleClose();
    } else {
      // mostrar notificação de falha
    }
  };

  return (
    <div className="flex">
      <Button
        variant="text"
        sx={{ minWidth: "fit-content" }}
        onClick={handleOpen}
      >
        <DeleteForeverOutlinedIcon htmlColor={theme.palette.error.main} />
      </Button>

      <Modal open={open} onClose={handleClose}>
        <div
          className="flex column end box-2 p-2"
          style={{
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0 0 100px black",
          }}
        >
          <div
            className="container flex center-y space-between"
            style={{
              marginBottom: 10,
            }}
          >
            <h2 className="m-0 primary">Remover</h2>
            <Button
              variant="text"
              sx={{ minWidth: "fit-content", padding: "0" }}
              onClick={handleClose}
            >
              <ClearOutlinedIcon htmlColor={theme.palette.error.main} />
            </Button>
          </div>
          <div className="flex column center">
            <span className="text-18 text-center my-1 box-8">
              Tem certeza de que deseja remover o item?
            </span>
            <span className="text-18 bold text-center primary my-3">
              {params.item.name || params.item.email}
            </span>
            <Button
              variant="contained"
              onClick={() => deleteItem(params.item)}
              sx={{
                minWidth: "fit-content",
                marginTop: "2%",
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteButton;
