import { FC, createContext, useContext, useState } from 'react';

import { RouteServices } from '../services/routes';
import { IHookProvider, IRoute, IRouteContext } from '../types';

/*
Creates a new context using a given interface as type
*/
const RoutesContext = createContext<IRouteContext>({} as IRouteContext);

/*
Determines state and CRUD functionalities for routes,
providing them to child components
*/
export const RoutesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const routeServices = new RouteServices();
	const [entities, setRoutes] = useState<IRoute[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchEntity, setSearchEntity] = useState<string>('');
	const [entitiesPerRow, setEntitiesPerRow] = useState<string>('15');
	const [paginate, setPaginate] = useState<number>(0);

	const createNewEntity = async (_user: IRoute) => {
		try {
			const user = await routeServices.createEntity(_user);
			fetchEntities();
			return user;
		} catch (_err) {
			throw _err;
		}
	};

	const editEntity = async (_id: string, _user: IRoute) => {
		try {
			const user = await routeServices.updateEntity(_id, _user);
			fetchEntities();
			return user;
		} catch (_err) {
			throw _err;
		}
	};

	const deleteEntity = async (_id: string) => {
		try {
			const user = await routeServices.deleteEntity(_id);
			fetchEntities();
			return user;
		} catch (_err) {
			throw _err;
		}
	};

	const fetchEntities = async () => {
		let routes: IRoute[] = [];
		setLoading(true);
		try {
			routes = await routeServices.getEntities();
		} catch (_err) {
			console.log(_err);
		} finally {
			setRoutes([...routes]);
			setLoading(false);
			return routes;
		}
	};

	const fetchEntity = async (_id: string) => {
		const user: IRoute = await routeServices.getEntity(_id);
		return user;
	};

	return (
		<RoutesContext.Provider
			value={{
				entities,
				loading,
				fetchEntities,
				fetchEntity,
				createNewEntity,
				editEntity,
				deleteEntity,
				searchEntity,
				setSearchEntity,
				entitiesPerRow,
				setEntitiesPerRow,
				paginate,
				setPaginate,
			}}
		>
			{_params.children}
		</RoutesContext.Provider>
	);
};

/*
Calls current context to be used in child component
*/
export function useRoutes() {
	const context = useContext(RoutesContext);

	if (!context) {
		throw new Error('useRoutes must be used within an RoutesProvider');
	}

	return context;
}
