const colors = {
  primary: "#2A5CAA",
  primaryLight: "#396BDA",
  primaryDark: "#1E3976",

  secondary: "#20BEC6",
  tertiary: "#20BEC6",

  warning: "#FFBA28",
  error: "#B00020",
  success: "#4CAF50",

  textColor: "#303030",
  textInfoColor: "#676767",

  background: "#fafafa",
  backgroundLighter: "#EBEBEB",

  backgroundStrokeActiveDark: "#A4A4A4",
  backgroundStrokeActive: "#F0F0F0",
  backgroundElementsDarker: "#ACAAAA",
  backgroundElements: "#D9D9D9",
  backgroundElementsLighter: "#E3E3E3",

  gray: "#919191",
  grayLight: "#D9D9D9",
  grayLighter: "#E4E4E4",
};

export { colors };
