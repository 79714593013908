import { AxiosResponse } from "axios";
import api from ".";

import { EntityServices } from "./entities";
import { IImages, IImagesAnalyses } from "src/types";

export class ImagesServices extends EntityServices<IImages> {

    constructor () {
        const _baseUrl: string = "/images";
        super("Imagem", _baseUrl)
    }

    async getImagesAnalyses (_query?: string, imageId?: string): Promise<IImagesAnalyses[]> {
        const response: AxiosResponse = await api.get(`${this.baseUrl}/${imageId}/analyses${_query ? _query : ''}`);

        if (response.data && response?.request?.status === 200) {
            return response.data
        }
        throw new Error(`Não foi possível buscar as analises da ${this.entityForLog}`);
    }

    async getImage (_id: string): Promise<any> {
        const response: AxiosResponse = await api.get(`${this.baseUrl}/${_id}/img`);

        if (response.data && response?.request?.status === 200) {
            return response.data
        }
        // throw new Error(`Não foi possível buscar o(a) ${this.entityForLog}`);
        console.log(new Error(`Não foi possível buscar o(a) ${this.entityForLog}`))
    }

}
