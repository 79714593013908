import { FC, useState, useEffect } from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Button } from '@mui/material';

import { useAuth } from 'src/hooks/auth';
import { useUsers } from 'src/hooks/users';
import { useOS } from 'src/hooks/ordersOfService';
import { useInspections } from 'src/hooks/inspections';
import { useComments } from 'src/hooks/comments';
import { theme } from 'src/components';
import { IAnalysisInfo, IUsers, IInspections } from 'src/types';
import { getStatusColor } from 'src/utils/consts';
import { parseDateToString, TIME_FORMAT } from 'src/utils/dates';

const AnalysisInfo: FC<IAnalysisInfo> = (params) => {
	const authHook = useAuth();
	const usersHook = useUsers();
	const osHook = useOS();
	const inpectionsHook = useInspections();
	const commentsHook = useComments();

	const [user, setUser] = useState<IUsers>();
	const [inspection, setInspection] = useState<IInspections>();
	const [isConfirmed, setIsConfirmed] = useState<number>(2);
	const [comments, setComments] = useState('');
	const [osStatus, setOsStatus] = useState(params.selectedOS.status);

	const getUser = async () => {
		const tempUser = await usersHook.fetchEntity(params.selectedOS.userId);
		setUser(tempUser);
		return tempUser;
	};

	const fetchInspection = async () => {
		const analysisInspections = await inpectionsHook.fetchEntities(
			'?analysis_id=' + params.imageAnalysis.analysis.id
		);

		if (analysisInspections.length > 0) {
			return analysisInspections[-1];
		}

		return await inpectionsHook.inspect(params.imageAnalysis.analysis.id);
	};

	const getOrCreateInspection = async () => {
		let tempInspection: IInspections = {
			id: '',
			tagHasAnomalies: 2,
			analysisId: params.imageAnalysis.analysis.id,
			userId: authHook.user?.id ?? '',
		};

		if (params.imageAnalysis.analysis.inspections.length > 0) {
			tempInspection = params.imageAnalysis.analysis.inspections[0];
		} else {
			tempInspection = await fetchInspection();
		}

		setInspection(tempInspection);
		setIsConfirmed(tempInspection.tagHasAnomalies);
	};

	const updateOS = async () => {
		const os = params.selectedOS;
		os.status = 'Em análise';
		const updatedOS = await osHook.editEntity(os.id, os);
		setOsStatus(updatedOS.status);
	};

	const updateInspection = async (inspection: IInspections, clickResult: number) => {
		inspection.tagHasAnomalies = clickResult;

		const updated_inspection = await inpectionsHook.editEntity(inspection.id, inspection);
		setInspection(updated_inspection);
		updateOS();
	};

	const updateComments = async () => {
		let tempUser: any = {};
		if (!authHook.user) tempUser = await getUser();

		const analysis = params.imageAnalysis.analysis;
		const payload = {
			id: '',
			body: comments,
			analysisId: analysis.id,
			userId: authHook.user?.id ?? tempUser.id,
		};

		let tempComments: any = null;
		if (analysis.comments.length > 0) {
			payload.id = analysis.comments[0].id;
			tempComments = await commentsHook.editEntity(payload.id, payload);
		} else {
			tempComments = await commentsHook.createNewEntity(payload);
		}

		if (tempComments) {
			setComments(payload.body);
		} else {
			setComments('');
		}
	};

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		getOrCreateInspection();
		setComments(params.imageAnalysis.analysis.comments[0]?.body ?? '');
	}, [params.imageAnalysis?.analysis]);

	useEffect(() => {
		setOsStatus(params.selectedOS.status);
	}, [params.selectedOS]);

	return (
		<div className='flex space-between' style={{ height: '128px', gap: '16px' }}>
			<div className='flex column' style={{ width: '49%', minWidth: '248px', gap: '8px' }}>
				<div className='container flex center-y' style={{ height: '32px', gap: '16px' }}>
					<div className='box-5 flex' style={{ gap: '16px' }}>
						<h2 className='primary' style={{ margin: 0 }}>
							OS
						</h2>
						<h2
							style={{
								margin: 0,
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{params.selectedOS.nid}
						</h2>
					</div>
					<div
						className='bold'
						style={{
							color: osStatus.includes('Concluída')
								? theme.palette.background.default
								: 'inherit',
							padding: '1% 3%',
							borderRadius: '15px',
							backgroundColor: getStatusColor(osStatus),
							whiteSpace: 'nowrap',
						}}
					>
						{osStatus}
					</div>
				</div>
				<div className='container flex center-y' style={{ height: '32px', gap: '16px' }}>
					<div className='box-5 flex center-y' style={{ gap: '16px' }}>
						<LocalOfferOutlinedIcon color='secondary' />
						<span
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{params.selectedTag.code +
								' | ' +
								params.selectedTag.name +
								' | ' +
								params.selectedTag.group}
						</span>
					</div>
					<div className='flex' style={{ gap: '16px' }}>
						<span className='bold secondary'>Início</span>
						<span>
							{params.selectedOS.startedAt
								? parseDateToString(params.selectedOS.startedAt, TIME_FORMAT)
								: '-'}
						</span>
					</div>
				</div>
				<div className='container flex center-y' style={{ height: '32px', gap: '16px' }}>
					<div className='box-5 flex center-y' style={{ gap: '16px' }}>
						<PersonOutlineOutlinedIcon color='secondary' />
						<span
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{user?.firstName + ' ' + user?.lastName}
						</span>
					</div>
					<div className='flex' style={{ gap: '16px' }}>
						<span className='bold secondary'>Fim</span>
						<span>
							{params.selectedOS.finishedAt
								? parseDateToString(params.selectedOS.finishedAt, TIME_FORMAT)
								: '-'}
						</span>
					</div>
				</div>
			</div>

			<div
				className='flex space-between'
				style={{ height: '100%', width: '49%', minWidth: '248px', gap: '16px' }}
			>
				<div className='box-4'>
					<span>Há anomalias?</span>
					{/* {params.selectedOS.finishedAt ? (
						<div
							className='flex space-between'
							style={{ marginTop: '8px', gap: '16px' }}
						>
							<Button
								key='b-1'
								variant='contained'
								unselectable='on'
								disableRipple
								disableElevation
								sx={{
									width: '45%',
									backgroundColor:
										isConfirmed === 1
											? theme.palette.error.main
											: theme.palette.backgroundElements.main,
									color:
										isConfirmed === 1
											? theme.palette.background.default
											: 'inherit',
									'&:hover': {
										backgroundColor:
											isConfirmed === 1
												? theme.palette.error.main
												: theme.palette.backgroundElements.main,
										color:
											isConfirmed === 1
												? theme.palette.background.default
												: 'inherit',
										cursor: 'default',
									},
								}}
							>
								<span>Sim</span>
							</Button>
							<Button
								key='b-2'
								variant='contained'
								unselectable='on'
								disableRipple
								disableElevation
								sx={{
									width: '45%',
									backgroundColor:
										isConfirmed === 0
											? theme.palette.success.main
											: theme.palette.backgroundElements.main,
									color:
										isConfirmed === 0
											? theme.palette.background.default
											: 'inherit',
									'&:hover': {
										backgroundColor:
											isConfirmed === 0
												? theme.palette.success.main
												: theme.palette.backgroundElements.main,
										color:
											isConfirmed === 0
												? theme.palette.background.default
												: 'inherit',
										cursor: 'default',
									},
								}}
							>
								<span>Não</span>
							</Button>
						</div>
					) : ( */}
					<div className='flex space-between' style={{ marginTop: '8px', gap: '16px' }}>
						<Button
							key='b-1'
							variant='contained'
							disableRipple
							onClick={() => {
								if (inspection) {
									const confirmed = inspection.tagHasAnomalies === 1 ? 2 : 1;
									setIsConfirmed(confirmed);
									updateInspection(inspection, confirmed);
								}
							}}
							sx={{
								width: '45%',
								backgroundColor:
									isConfirmed !== 1
										? theme.palette.backgroundElements.main
										: theme.palette.error.main,
								color:
									isConfirmed !== 1
										? 'inherit'
										: theme.palette.background.default,
								'&:hover': {
									backgroundColor:
										isConfirmed !== 1
											? theme.palette.backgroundElements.main
											: theme.palette.error.light,
								},
							}}
						>
							<span>Sim</span>
						</Button>
						<Button
							key='b-2'
							variant='contained'
							disableRipple
							onClick={() => {
								if (inspection) {
									const confirmed = inspection.tagHasAnomalies === 0 ? 2 : 0;
									setIsConfirmed(confirmed);
									updateInspection(inspection, confirmed);
								}
							}}
							sx={{
								width: '45%',
								backgroundColor:
									isConfirmed > 0
										? theme.palette.backgroundElements.main
										: theme.palette.success.main,
								color:
									isConfirmed > 0 ? 'inherit' : theme.palette.background.default,
								'&:hover': {
									backgroundColor:
										isConfirmed > 0
											? theme.palette.backgroundElements.main
											: theme.palette.success.light,
								},
							}}
						>
							<span>Não</span>
						</Button>
					</div>
					{/* )} */}
				</div>

				<div className='box-5 flex column' style={{ height: '100%' }}>
					<span>Observações</span>
					<form
						onBlur={() => updateComments()}
						style={{ width: '100%', height: '100%', marginTop: '8px' }}
					>
						<label htmlFor='comments' hidden></label>
						<textarea
							name='comments'
							placeholder={
								comments.length > 0 ? comments : 'Descreva aqui as suas observações'
							}
							style={{
								font: 'inherit',
								padding: '8px',
								width: '95%',
								height: '85%',
								border: `solid 1px ${theme.palette.backgroundElements.main}`,
								borderRadius: '3px',
							}}
							value={comments}
							onChange={(event) => setComments(event.target.value)}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AnalysisInfo;
