import { FC, useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { ISingleSelect } from 'src/types';
import { parseDateToString, DATE_FORMAT } from 'src/utils/dates';
import { getStatusColor } from 'src/utils/consts';

const SingleSelectWithSearchBar: FC<ISingleSelect> = (params) => {
	const [search, setSearch] = useState<string>('');
	const [filteredItens, setFilteredItens] = useState<any[]>(params.itens);

	useEffect(() => {
		let auxItens = [...params.itens];
		if (search !== '') {
			auxItens = auxItens.filter((item) => {
				return (
					item?.name?.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
					item?.nid?.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
					item?.startedAt?.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
					item?.group?.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
					item?.code?.toUpperCase().indexOf(search.toUpperCase()) >= 0
				);
			});
		}
		setFilteredItens([...auxItens]);
	}, [search]);

	useEffect(() => {
		setFilteredItens(params.itens);
	}, [params.itens]);

	return (
		<div>
			<TextField
				variant='outlined'
				size='small'
				label=''
				helperText={null}
				placeholder=' Pesquisar'
				value={search}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					setSearch(event.target.value)
				}
				InputProps={{
					startAdornment: <SearchIcon fontSize='small' color='secondary' />,
				}}
				sx={{
					borderRadius: '3px',
					margin: '8px 0',
					'& .MuiInputBase-root': { paddingLeft: '8px' },
					input: { padding: '8px 8px 8px 0', fontSize: '11pt', lineHeight: '100%' },
					'input::placeholder': { fontSize: '11pt' },
				}}
			/>

			<div
				style={{
					height: '140px',
					border: '1px solid rgba(0, 0, 0, 0.23)',
					borderRadius: '3px',
					padding: '8px',
					overflowY: 'scroll',
				}}
			>
				{filteredItens.length > 0 ? (
					filteredItens.map((item: any, index: number) => (
						<Button
							key={index}
							unselectable={item?.id === params.selectedItem?.id ? 'on' : 'off'}
							color={item?.id === params.selectedItem?.id ? 'secondary' : 'inherit'}
							disableRipple
							onClick={() => params.selectAction(item)}
							sx={{
								padding: 0,
								width: '100%',
								justifyContent: 'start',
								'&:hover': {
									backgroundColor: 'transparent',
									color:
										item?.id === params.selectedItem?.id
											? '#20bec6'
											: '#2a5caa',
									cursor:
										item?.id === params.selectedItem?.id
											? 'default'
											: 'pointer',
								},
							}}
						>
							{item?.nid ? (
								<div
									className='container flex center-y'
									style={{ padding: '8px 0', gap: '8px' }}
								>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '11pt',
											whiteSpace: 'nowrap',
										}}
									>
										{item.nid}
									</span>
									<span
										style={{
											fontSize: '11pt',
											lineHeight: '100%',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{parseDateToString(item.startedAt, DATE_FORMAT)}
									</span>
									<div
										style={{
											width: '8px',
											minWidth: '8px',
											height: '8px',
											minHeight: '8px',
											borderRadius: '8px',
											backgroundColor: getStatusColor(item.status),
										}}
									/>
								</div>
							) : item?.code ? (
								<div
									className='flex center-y'
									style={{
										gap: '8px',
										padding: '4px 0',
										overflow: 'hidden',
									}}
								>
									<span
										className='flex'
										style={{
											fontSize: '11pt',
											lineHeight: '11pt',
											whiteSpace: 'nowrap',
										}}
									>
										{item.code}
									</span>
									<div
										style={{
											textAlign: 'left',
											overflow: 'inherit',
										}}
									>
										<span
											style={{
												display: 'block',
												fontSize: '11pt',
												lineHeight: '100%',
												textTransform: 'capitalize',
												whiteSpace: 'nowrap',
												overflow: 'inherit',
												textOverflow: 'ellipsis',
											}}
										>
											{item.name}
										</span>
										<span
											style={{
												fontSize: '11pt',
												lineHeight: '100%',
												textTransform: 'capitalize',
												whiteSpace: 'nowrap',
												overflow: 'inherit',
												textOverflow: 'ellipsis',
											}}
										>
											{item.group}
										</span>
									</div>
								</div>
							) : (
								<span
									style={{
										fontSize: '11pt',
										textTransform: 'capitalize',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{item.name}
								</span>
							)}
						</Button>
					))
				) : (
					<span style={{ fontSize: '14px' }}>Não há itens para selecionar</span>
				)}
			</div>
		</div>
	);
};

export default SingleSelectWithSearchBar;
