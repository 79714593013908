import { EntityServices } from "./entities";
import { IComments } from "src/types";

export class CommentsServices extends EntityServices<IComments> {

  constructor () {
    const _baseUrl: string = "/comments";
    super("Observações", _baseUrl)
  }
}
