import { FC } from 'react';

import { Button } from '@mui/material';
import { ISideMenu } from 'src/types';
import { translateData } from 'src/utils/translate';

const SideMenu: FC<ISideMenu> = (params) => {
	return (
		<div
			className='flex column bg'
			style={{
				minWidth: '110px',
				width: '140px',
				gap: '16px',
				padding: '24px 16px 24px 32px',
			}}
		>
			{params.items.map(
				(page: string, index: number) =>
					page !== 'tags' &&
					(params.selectedItem !== page ? (
						<Button
							key={index}
							sx={{
								width: 'fit-content',
								textTransform: 'capitalize',
								fontSize: '14pt',
								textAlign: 'left',
								'&:hover': { backgroundColor: 'transparent' },
							}}
							color='inherit'
							disableRipple
							onClick={() => params.selectAction(page)}
						>
							{translateData(page)}
						</Button>
					) : (
						<Button
							key={index}
							sx={{
								width: 'fit-content',
								textTransform: 'capitalize',
								fontSize: '14pt',
								fontWeight: 'bold',
								textAlign: 'left',
								'&:hover': { backgroundColor: 'transparent' },
							}}
							color='primary'
							disableRipple
							onClick={() => params.selectAction(page)}
						>
							{translateData(page)}
						</Button>
					))
			)}
		</div>
	);
};

export default SideMenu;
