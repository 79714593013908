import { AxiosResponse } from "axios";

import api from ".";
import { EntityServices } from "./entities";
import { IRoles } from "src/types";

export class RolesServices extends EntityServices<IRoles> {

    constructor () {
        const _baseUrl: string = "/roles";
        super("Permissão", _baseUrl)
    }

    async getGroups (_query?: string): Promise<string[]> {
        const response: AxiosResponse = await api.get(`${this.baseUrl}/groups${_query ? _query : ''}`);

        if (response.data && response?.request?.status === 200) {
            return response.data;
        }
        throw new Error(`Não foi possível buscar os grupos`);
    }

}
