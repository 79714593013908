import { EntityServices } from "./entities";
import { IAnalysis } from "src/types";

export class AnalysisServices extends EntityServices<IAnalysis> {

  constructor () {
    const _baseUrl: string = "/analyses";
    super("Análise", _baseUrl)
  }
}
