import { FC, createContext, useContext, useState } from 'react';
import { DevicesServices } from 'src/services/devices';
import { IDevices, IDevicesContext, IHookProvider } from 'src/types';

/*
Creates a new context using a given interface as type
*/
const DevicesContext = createContext<IDevicesContext>({} as IDevicesContext);

/**
Applies state and CRUD functionalities for device objects,
providing them to child components
@provider
*/
export const DevicesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const deviceServices = new DevicesServices();

	const [entities, setDevices] = useState<IDevices[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchEntity, setSearchEntity] = useState<string>('');
	const [entitiesPerRow, setEntitiesPerRow] = useState<string>('8');
	const [paginate, setPaginate] = useState<number>(0);

	const createNewEntity = async (_device: IDevices) => {
		try {
			const device = await deviceServices.createEntity(_device);

			fetchEntities();
			return device;
		} catch (_err) {
			throw _err;
		}
	};

	const editEntity = async (_id: string, _device: IDevices) => {
		try {
			const device = await deviceServices.updateEntity(_id, _device);

			fetchEntities();
			return device;
		} catch (_err) {
			throw _err;
		}
	};

	const deleteEntity = async (_id: string) => {
		try {
			const device = await deviceServices.deleteEntity(_id);

			fetchEntities();
			return device;
		} catch (_err) {
			throw _err;
		}
	};

	const fetchEntities = async (_query?: string) => {
		let devices: IDevices[] = [];
		setLoading(true);
		try {
			devices = await deviceServices.getEntities(_query);
		} catch (_err) {
			console.log(_err);
		} finally {
			setDevices([...devices]);
			setLoading(false);
			return devices;
		}
	};

	const fetchEntity = async (_id: string) => {
		const device: IDevices = await deviceServices.getEntity(_id);
		return device;
	};

	return (
		<DevicesContext.Provider
			value={{
				entities,
				loading,
				fetchEntities,
				fetchEntity,
				createNewEntity,
				editEntity,
				deleteEntity,

				searchEntity,
				setSearchEntity,
				entitiesPerRow,
				setEntitiesPerRow,
				paginate,
				setPaginate,
			}}
		>
			{_params.children}
		</DevicesContext.Provider>
	);
};

/*
Calls current context to be used in child component
*/
export function useDevices() {
	const context = useContext(DevicesContext);

	if (!context) {
		throw new Error('useDevices must be used within an DevicesProvider');
	}

	return context;
}
