import { FC, createContext, useContext, useState } from 'react';
import { TagsServices } from 'src/services/tags';
import { ITags, ITagsContext, IHookProvider } from 'src/types';

/*
Creates a new context using a given interface as type
*/
const TagsContext = createContext<ITagsContext>({} as ITagsContext);

/**
Applies state and CRUD functionalities for tag objects,
providing them to child components
@provider
*/
export const TagsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const tagServices = new TagsServices();

	const [entities, setTags] = useState<ITags[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchEntity, setSearchEntity] = useState<string>('');
	const [entitiesPerRow, setEntitiesPerRow] = useState<string>('8');
	const [paginate, setPaginate] = useState<number>(0);

	const createNewEntity = async (_tag: ITags) => {
		try {
			const tag = await tagServices.createEntity(_tag, _tag.clientId);

			fetchEntities('', _tag.clientId);
			return tag;
		} catch (_err) {
			throw _err;
		}
	};

	const editEntity = async (_id: string, _tag: ITags) => {
		try {
			const tag = await tagServices.updateEntity(_id, _tag, _tag.clientId);

			fetchEntities('', _tag.clientId);
			return tag;
		} catch (_err) {
			throw _err;
		}
	};

	const deleteEntity = async (_id: string) => {
		const _tag = entities.find((tag) => tag.id === _id);
		try {
			const tag = await tagServices.deleteEntity(_id, _tag?.clientId);

			fetchEntities('', _tag?.clientId);
			return tag;
		} catch (_err) {
			throw _err;
		}
	};

	const fetchEntities = async (_query?: string, clientId?: string) => {
		let tags: ITags[] = [];
		setLoading(true);
		try {
			tags = await tagServices.getEntities(_query, clientId);
		} catch (_err) {
			console.log(_err);
		} finally {
			setTags([...tags]);
			setLoading(false);
			return tags;
		}
	};

	const fetchEntity = async (_id: string, clientId?: string) => {
		const tag: ITags = await tagServices.getEntity(_id, clientId);
		return tag;
	};

	return (
		<TagsContext.Provider
			value={{
				entities,
				setTags,
				loading,
				fetchEntities,
				fetchEntity,
				createNewEntity,
				editEntity,
				deleteEntity,

				searchEntity,
				setSearchEntity,
				entitiesPerRow,
				setEntitiesPerRow,
				paginate,
				setPaginate,
			}}
		>
			{_params.children}
		</TagsContext.Provider>
	);
};

/*
Calls current context to be used in child component
*/
export function useTags() {
	const context = useContext(TagsContext);

	if (!context) {
		throw new Error('useTags must be used within an TagsProvider');
	}

	return context;
}
