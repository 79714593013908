import { FC, useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import api from 'src/services';
import { IAnalysisImages } from 'src/types';
import { theme, ImageZoomModalButton, ConfirmAnalysisAction } from 'src/components';
import { NO_IMAGE } from 'src/utils/consts';

const AnalysisImagesCompare: FC<IAnalysisImages> = (params) => {
	const [prevImage, setPrevImage] = useState<string>();
	const [currentImage, setcurrentImage] = useState<string>();

	const getImgFile = async (link: string, setFunc: any) => {
		if (link) {
			const response: AxiosResponse = await api.get(link);
			if (response.data) {
				setFunc(response.data);
			}
		}
	};

	useEffect(() => {
		getImgFile(params.imageAnalysis?.previousImage?.link, setPrevImage);
	}, [params.imageAnalysis?.previousImage?.link]);

	useEffect(() => {
		getImgFile(params.imageAnalysis?.currentImage?.link, setcurrentImage);
	}, [params.imageAnalysis?.currentImage?.link]);

	return (
		<div className='flex space-between' style={{ height: '80%', gap: '16px' }}>
			{prevImage ? (
				<div className='flex column' style={{ width: '49%', minWidth: '248px' }}>
					<span className='bold primary'>Anterior</span>
					<div
						className='flex center'
						style={{
							position: 'relative',
							height: '100%',
							overflow: 'hidden',
							border: `1px solid ${theme.palette.backgroundElements.main}`,
							borderRadius: '3px',
							backgroundColor: theme.palette.background.paper,
						}}
					>
						<img
							width='100%'
							src={`data:image/jpeg;base64,${prevImage}`}
							alt='Frame anterior capturada da anomalia detectada'
							onError={(event) => {
								event.currentTarget.src = NO_IMAGE;
							}}
						/>
						<ImageZoomModalButton image_path={`data:image/jpeg;base64,${prevImage}`} />
					</div>
				</div>
			) : (
				<div className='flex column' style={{ width: '49%', minWidth: '248px' }}>
					<span className='bold primary'>Anterior</span>
					<div
						className='flex center'
						style={{
							height: '100%',
							overflow: 'hidden',
							border: `1px solid ${theme.palette.backgroundElements.main}`,
							borderRadius: '3px',
						}}
					>
						<img height='100%' src={NO_IMAGE} alt='Imagem anterior não encontrada' />
					</div>
				</div>
			)}

			{currentImage ? (
				<div className='flex column' style={{ width: '49%', minWidth: '248px' }}>
					<span className='bold primary'>Atual</span>
					<div
						className='flex center'
						style={{
							position: 'relative',
							height: '100%',
							overflow: 'hidden',
							border: `1px solid ${theme.palette.backgroundElements.main}`,
							borderRadius: '3px',
							backgroundColor: theme.palette.background.paper,
						}}
					>
						<img
							width='100%'
							src={`data:image/jpeg;base64,${currentImage}`}
							alt='Frame atual capturada da anomalia detectada'
							onError={(event) => {
								event.currentTarget.src = NO_IMAGE;
							}}
						/>
						<ImageZoomModalButton
							image_path={`data:image/jpeg;base64,${currentImage}`}
						/>
						<ConfirmAnalysisAction analysis={params.imageAnalysis.analysis} />
					</div>
				</div>
			) : (
				<div className='flex column' style={{ width: '49%', minWidth: '248px' }}>
					<span className='bold primary'>Atual</span>
					<div
						className='flex center'
						style={{
							height: '100%',
							overflow: 'hidden',
							border: `1px solid ${theme.palette.backgroundElements.main}`,
							borderRadius: '3px',
						}}
					>
						<img height='100%' src={NO_IMAGE} alt='Imagem atual não encontrada' />
					</div>
				</div>
			)}
		</div>
	);
};

export default AnalysisImagesCompare;
