import axios, { AxiosResponse, AxiosError } from "axios";
import keycloakClientGenerator from './keycloak';

let appUrl = "https://web.naturgy.pixforce.ai";
let apiUrl = "https://api.naturgy.pixforce.ai";
let keycloakUrl = "https://keycloak.naturgy.pixforce.ai";
let keycloakRealm = "Naturgy";
let keycloakClientId = "web-platform";

if (process.env) {
    appUrl = process.env.PIX_QUALITY_APP_URL ?? "https://web.naturgy.pixforce.ai";
    apiUrl = process.env.PIX_QUALITY_API_URL ?? "https://api.naturgy.pixforce.ai";
    keycloakUrl = process.env.PIX_QUALITY_KEYCLOAK_URL ?? "https://keycloak.naturgy.pixforce.ai";
    keycloakRealm = process.env.PIX_QUALITY_KEYCLOAK_REALM ?? "Naturgy";
    keycloakClientId = process.env.PIX_QUALITY_KEYCLOAK_CLIENT_ID ?? "web-platform";

} else if (appUrl === "https://web.naturgy.pixforce.ai" && (window as any)._env_) {
    appUrl = (window as any)._env_.PIX_QUALITY_APP_URL;
    apiUrl = (window as any)._env_.PIX_QUALITY_API_URL;
    keycloakUrl = (window as any)._env_.PIX_QUALITY_KEYCLOAK_URL;
    keycloakRealm = (window as any)._env_.PIX_QUALITY_KEYCLOAK_REALM;
    keycloakClientId = (window as any)._env_.PIX_QUALITY_KEYCLOAK_CLIENT_ID;
}  

export const APP_URL = appUrl;
export const API_URL = apiUrl;
export const KEYCLOAK_URL = keycloakUrl;
export const KEYCLOAK_REALM = keycloakRealm;
export const KEYCLOAK_CLIENT_ID = keycloakClientId;

const api = axios.create({
    baseURL: API_URL,
    // headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use(
    (config) => {
        const keycloakClient = keycloakClientGenerator();
        if (keycloakClient.token) {
            config.headers.Authorization = `Bearer ${keycloakClient.token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response: AxiosResponse) => Promise.resolve(response),
    (error: AxiosError) => {
        // if (error?.response?.status === 401) {
        //     if (window.location.pathname !== "/") {
        //     window.location.href = "/";
        //     }
        // }
        return Promise.reject(error);
    }
);

export default api;
