import { FC, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker, ptBR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';

import { IReportFilter, IReportFilterParams } from 'src/types';
import { blankFilterParams } from 'src/utils/consts';

const detectionChoices = [
	{ value: '-1', name: 'Todas' },
	{ value: '1', name: 'Concluída c/ anomalias' },
	{ value: '0', name: 'Concluída s/ anomalias' },
	// { value: '2', name: 'Recusada' },
	// { value: '3', name: 'Ambas' },
];

/**
 * This component returns an CRUD Data Grid customized
 * It uses mui styles
 * @component
 */
const ReportFilter: FC<IReportFilter> = (props: IReportFilter) => {
	const [initialDate, setInitialDate] = useState<Date | null>(blankFilterParams.startDate);
	const [finalDate, setFinalDate] = useState<Date | null>(blankFilterParams.endDate);
	const [initialTime, setInitialTime] = useState<Date | null>(blankFilterParams.startTime);
	const [finalTime, setFinalTime] = useState<Date | null>(blankFilterParams.endTime);
	const [clients, setClients] = useState<string[]>(blankFilterParams.clientsNames);
	const [devices, setDevices] = useState<string[]>(blankFilterParams.deviceNames);
	const [detection, setDetection] = useState<string | undefined>(
		blankFilterParams.analysisIsAiRight
	);
	const [orderByDesc, setOrderByDesc] = useState<boolean | undefined>(
		blankFilterParams.orderByDesc
	);
	const [orderByDateTime, setOrderByDateTime] = useState<boolean | undefined>(
		blankFilterParams.orderByDateTime
	);
	const [orderById, setOrderById] = useState<boolean | undefined>(blankFilterParams.orderById);
	const [orderByDetection, setOrderByDetection] = useState<boolean | undefined>(
		blankFilterParams.orderByDetection
	);
	const [orderByTags, setOrderByTags] = useState<boolean | undefined>(
		blankFilterParams.orderByTags
	);
	const [orderByClient, setOrderByClient] = useState<boolean | undefined>(
		blankFilterParams.orderByClientName
	);
	const [orderByDevice, setOrderByDevice] = useState<boolean | undefined>(
		blankFilterParams.orderByDeviceName
	);

	useEffect(() => {
		// If there is an default params based on another routine, it can be called
		if (props.defaultParams) fillFilterParams(props.defaultParams);
	}, []);

	const onChangeOrderByCheckboxes = (value: boolean, setValue: (auxValue: boolean) => void) => {
		setValue(value);
	};

	const cleanFilter = () => {
		fillFilterParams(blankFilterParams);
	};

	const fillFilterParams = (toFillFilterParams: IReportFilterParams) => {
		setInitialDate(toFillFilterParams.startDate);
		setFinalDate(toFillFilterParams.endDate);
		setInitialTime(toFillFilterParams.startTime);
		setFinalTime(toFillFilterParams.endTime);
		setClients(toFillFilterParams.clientsNames);
		setDevices(toFillFilterParams.deviceNames);
		setDetection(toFillFilterParams.analysisIsAiRight);
		setOrderByDesc(toFillFilterParams.orderByDesc);
		setOrderByDateTime(toFillFilterParams.orderByDateTime);
		setOrderById(toFillFilterParams.orderById);
		setOrderByDetection(toFillFilterParams.orderByDetection);
		setOrderByTags(toFillFilterParams.orderByTags);
		setOrderByDevice(toFillFilterParams.orderByDeviceName);
	};

	const filter = () => {
		const filterParams: IReportFilterParams = {
			startDate: initialDate,
			endDate: finalDate,
			startTime: initialTime ? new Date(initialTime.valueOf()) : null,
			endTime: finalTime ? new Date(finalTime.valueOf()) : null,
			clientsNames: clients,
			deviceNames: devices,
			analysisIsAiRight: detection,
			orderByDesc,
			orderByDateTime,
			orderById,
			orderByDetection,
			orderByTags,
			orderByDeviceName: orderByDevice,
			orderByClientName: orderByClient,
		};

		props.onFilter(filterParams);
	};

	return (
		<Box
			display='flex'
			flexDirection='column'
			flex={1}
			flexGrow={1}
			alignItems='center'
			paddingX='40px'
			paddingY='24px'
			sx={{
				backgroundColor: '#F5F5F5',
				borderTop: 'solid 1px #E0E0E0',
				borderBottom: 'solid 1px #E0E0E0',
			}}
		>
			<Grid container columnSpacing='24px' rowSpacing='24px'>
				<Grid item xs={5} lg={2.3}>
					<Grid container rowSpacing='24px'>
						<Grid
							item
							xs={12}
							display='flex'
							flexDirection='column'
							justifyContent='center'
						>
							<Typography
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Data início
							</Typography>
							<LocalizationProvider
								localeText={
									ptBR.components.MuiLocalizationProvider.defaultProps.localeText
								}
								dateAdapter={AdapterDayjs}
								adapterLocale='pt-br'
							>
								<DatePicker
									value={initialDate}
									onChange={(newValue: any) => setInitialDate(newValue)}
									slotProps={{
										textField: {
											size: 'small',
											variant: 'outlined',
											error: false,
											fullWidth: true,
											placeholder: 'Informe a data inicial',
										},
									}}
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={12} display='flex' flexDirection='column' paddingY='8px'>
							<Typography
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Data fim
							</Typography>
							<LocalizationProvider
								localeText={
									ptBR.components.MuiLocalizationProvider.defaultProps.localeText
								}
								dateAdapter={AdapterDayjs}
								adapterLocale='pt-br'
							>
								<DatePicker
									value={finalDate}
									onChange={(newValue: any) => setFinalDate(newValue)}
									slotProps={{
										textField: {
											size: 'small',
											variant: 'outlined',
											error: false,
											fullWidth: true,
											placeholder: 'Informe a data final',
										},
									}}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={0.1}>
					<Divider orientation='vertical' />
				</Grid>

				<Grid item xs={5} lg={2.3}>
					<Grid container rowSpacing='24px'>
						<Grid
							item
							xs={12}
							display='flex'
							flexDirection='column'
							justifyContent='center'
						>
							<Typography
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Hora início
							</Typography>
							<LocalizationProvider
								localeText={
									ptBR.components.MuiLocalizationProvider.defaultProps.localeText
								}
								dateAdapter={AdapterDayjs}
								adapterLocale='pt-br'
							>
								<TimePicker
									value={initialTime}
									onChange={(newValue: any) => setInitialTime(newValue)}
									slotProps={{
										textField: {
											size: 'small',
											variant: 'outlined',
											error: false,
											fullWidth: true,
											placeholder: 'Informe a hora inicial',
										},
									}}
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={12} display='flex' flexDirection='column' paddingY='8px'>
							<Typography
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Hora fim
							</Typography>
							<LocalizationProvider
								localeText={
									ptBR.components.MuiLocalizationProvider.defaultProps.localeText
								}
								dateAdapter={AdapterDayjs}
								adapterLocale='pt-br'
							>
								<TimePicker
									value={finalTime}
									onChange={(newValue: any) => setFinalTime(newValue)}
									slotProps={{
										textField: {
											size: 'small',
											variant: 'outlined',
											error: false,
											fullWidth: true,
											placeholder: 'Informe a hora final',
										},
									}}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={0.1}>
					<Divider orientation='vertical' />
				</Grid>
				<Grid item xs={5} lg={2.3}>
					<Grid container rowSpacing='24px'>
						<Grid
							item
							xs={12}
							display='flex'
							flexDirection='column'
							justifyContent='center'
						>
							<Typography
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Cliente
							</Typography>
							<FormControl fullWidth>
								<Select
									size='small'
									multiple
									value={clients}
									onChange={(event: SelectChangeEvent<typeof clients>) => {
										const {
											target: { value },
										} = event;
										setClients(
											typeof value === 'string' ? value.split(',') : value
										);
									}}
									input={<OutlinedInput label='' />}
									renderValue={(selected) => selected.join(', ')}
								>
									<MenuItem value='Todos'>
										<Checkbox checked={clients.indexOf('Todos') > -1} />
										<ListItemText primary='Todos' />
									</MenuItem>
									{props.clientsEntities.map((item: string, index: number) => {
										return (
											<MenuItem key={index} value={item}>
												<Checkbox checked={clients.indexOf(item) > -1} />
												<ListItemText primary={item} />
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} display='flex' flexDirection='column' paddingY='8px'>
							<Typography
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Detecção
							</Typography>
							<FormControl fullWidth>
								<Select
									size='small'
									defaultValue='-1'
									value={detection}
									onChange={(event: SelectChangeEvent) => {
										if (event.target.value.toString() === '-1') {
											setDetection(undefined);
										} else {
											setDetection(event.target.value as string);
										}
									}}
								>
									{detectionChoices.map((choice) => (
										<MenuItem value={choice.value}>{choice.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={0.1}>
					<Divider orientation='vertical' />
				</Grid>
				<Grid item xs={12} lg={4.4} display='flex' flexDirection='column'>
					<Typography
						fontSize='16px'
						fontWeight='400'
						lineHeight='24px'
						letterSpacing='0.5px'
						textTransform='initial'
					>
						Ordenar por
					</Typography>
					<FormControl>
						<Select
							size='small'
							value={orderByDesc ? '1' : '0'}
							onChange={(event: SelectChangeEvent) => {
								if ((event.target.value as string) === '1') setOrderByDesc(true);
								else setOrderByDesc(false);
							}}
							sx={{ maxWidth: '200px' }}
						>
							<MenuItem value={'0'}>Crescente</MenuItem>
							<MenuItem value={'1'}>Decrescente</MenuItem>
						</Select>
					</FormControl>

					<Grid container>
						<Grid item xs={6} md={3} display='flex' flexDirection='column'>
							<FormControlLabel
								label='Data e Hora'
								control={
									<Checkbox
										checked={orderByDateTime}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onChangeOrderByCheckboxes(
												event.target.checked,
												setOrderByDateTime
											)
										}
									/>
								}
							/>
							<FormControlLabel
								label='Cliente'
								control={
									<Checkbox
										checked={orderByClient}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onChangeOrderByCheckboxes(
												event.target.checked,
												setOrderByClient
											)
										}
									/>
								}
							/>
							{/* <FormControlLabel
								label='Detecção'
								control={
									<Checkbox
										checked={orderByDetection}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onChangeOrderByCheckboxes(
												event.target.checked,
												setOrderByDetection
											)
										}
									/>
								}
							/> */}
						</Grid>
						<Grid item xs={6} md={3} display='flex' flexDirection='column'>
							<FormControlLabel
								label='ID'
								control={
									<Checkbox
										checked={orderById}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onChangeOrderByCheckboxes(
												event.target.checked,
												setOrderById
											)
										}
									/>
								}
							/>
							{/* <FormControlLabel
								label='Cliente'
								control={
									<Checkbox
										checked={orderByClient}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onChangeOrderByCheckboxes(
												event.target.checked,
												setOrderByClient
											)
										}
									/>
								}
							/> */}
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							display='flex'
							flexDirection='column'
							justifyContent='space-evenly'
						>
							<Button
								size='small'
								variant='outlined'
								color='inherit'
								disableRipple
								onClick={cleanFilter}
							>
								<Typography
									fontSize='16px'
									fontWeight='700'
									lineHeight='24px'
									letterSpacing='0.5px'
									textTransform='initial'
								>
									Limpar filtros
								</Typography>
							</Button>
							<Button
								size='small'
								variant='contained'
								color='secondary'
								disableRipple
								disableElevation
								onClick={filter}
							>
								<Typography
									color='white'
									fontSize='16px'
									fontWeight='700'
									lineHeight='24px'
									letterSpacing='0.5px'
									textTransform='initial'
								>
									Filtrar
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ReportFilter;
