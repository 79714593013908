import { FC, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Collapse,
	Grid,
	IconButton,
	Pagination,
	TextField,
	Typography,
} from '@mui/material';
import {
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	Search as SearchIcon,
} from '@mui/icons-material';

// import { useAnalyses } from 'src/hooks/analyses';
import { useOS } from 'src/hooks/ordersOfService';
import { useClients } from 'src/hooks/clients';
import { useDevices } from 'src/hooks/devices';
import {
	ReportConfirmModal,
	ReportFilter,
	ReportItem,
	// LoadingDisplay,
	theme,
} from 'src/components';
// import { IAnalysis, IReportGenerate, IReportFilterParams, IOrders } from 'src/types';
import { IReportGenerate, IReportFilterParams, IOrders } from 'src/types';

/**
 * This component returns the Reports page
 * It uses mui styles
 * @component
 */
const ReportsPage: FC = () => {
	// const analysesHook = useAnalyses();
	const ordersHook = useOS();
	const clientsHook = useClients();
	const devicesHook = useDevices();

	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [reportFilterParams, setReportFilterParams] = useState<IReportFilterParams | undefined>(
		undefined
	);
	// const [filteredAnalysis, setFilteredAnalysis] = useState<IAnalysis[]>([]);
	const [filteredOrders, setFilteredOrders] = useState<IOrders[]>([]);
	const [search, setSearch] = useState<string>('');
	const [clients, setClients] = useState<string[]>([]);
	const [devices, setDevices] = useState<string[]>([]);
	const [selectedAnalysisIds, setSelectedAnalysisIds] = useState<string[]>([]);
	const [openReportConfirmModal, setOpenReportConfirmModal] = useState<boolean>(false);

	const filter = async (params: IReportFilterParams) => {
		// const query: string = analysesHook.createQuery(params);
		// analysesHook.fetchEntities(query);
		const query: string = ordersHook.createQuery(params);
		ordersHook.fetchEntities(query);

		setReportFilterParams(params);
	};

	const select = (analysisId: string) => {
		const auxSelectedAnalysisIds = [...selectedAnalysisIds];

		const index = auxSelectedAnalysisIds.indexOf(analysisId);
		if (index !== -1) auxSelectedAnalysisIds.splice(index, 1);
		else auxSelectedAnalysisIds.push(analysisId);

		setSelectedAnalysisIds([...auxSelectedAnalysisIds]);
	};

	const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
		// analysesHook.setPaginate(value - 1);
		ordersHook.setPaginate(value - 1);
	};

	const onOpenReportConfirmModal = () => {
		setOpenReportConfirmModal(true);
	};

	const onReport = (withImages: boolean) => {
		try {
			setOpenReportConfirmModal(false);

			let query: string = '';
			let payload: IReportGenerate = {
				file_name: undefined,
				with_images: withImages,
			};

			if (selectedAnalysisIds.length > 0) payload.ids = selectedAnalysisIds;
			else if (reportFilterParams) {
				// query = analysesHook.createQuery(reportFilterParams);
				query = ordersHook.createQuery(reportFilterParams);
			}

			// analysesHook.generateReport(payload, query);
			ordersHook.generateReport(payload, query);
			alert('Por favor aguarde enquanto o relatório está sendo gerado');
		} catch (err) {
			alert(err);
		}
	};

	useEffect(() => {
		ordersHook.clearOrders();
		clientsHook.fetchEntities();
		// devicesHook.fetchEntities();
	}, []);

	useEffect(() => {
		if (showFilter) {
			// analysesHook.setEntitiesPerRow('5');
			ordersHook.setEntitiesPerRow('5');
		} else {
			// analysesHook.setEntitiesPerRow('8');
			ordersHook.setEntitiesPerRow('8');
		}
	}, [showFilter]);

	useEffect(() => {
		const temp_devices: string[] = [];

		for (let index = 0; index < devicesHook.entities.length; index++) {
			temp_devices.push(devicesHook.entities[index].name);
		}

		setDevices(temp_devices);
	}, [devicesHook.entities]);

	useEffect(() => {
		const temp_clients: string[] = [];

		for (let index = 0; index < clientsHook.entities.length; index++) {
			temp_clients.push(clientsHook.entities[index].name);
		}

		setClients(temp_clients);
	}, [clientsHook.entities]);

	// useEffect(() => {
	// 	setFilteredOrders(analysesHook.entities);
	// }, [analysesHook.entities]);

	useEffect(() => {
		setFilteredOrders(ordersHook.entities);
	}, [ordersHook.entities]);

	useEffect(() => {
		// let auxFilteredAnalysis: IAnalysis[] = [...analysesHook.entities];
		let auxFilteredOrders: IOrders[] = [...ordersHook.entities];
		if (search !== '') {
			auxFilteredOrders = auxFilteredOrders.filter((item) => {
				const id: any = item.id?.match(/[^-]+/g)?.[1];
				return (
					id && id.toUpperCase().indexOf(search.toUpperCase()) >= 0
					// ||
					// item.images
					// 	.map((item) => item.device.name)
					// 	.join(' ')
					// 	.toUpperCase()
					// 	.indexOf(search.toUpperCase()) >= 0
				);
			});
		}
		setFilteredOrders([...auxFilteredOrders]);
	}, [search]);

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			paddingY='3vh'
			width='100%'
			height='84vh'
		>
			<Grid
				container
				display='flex'
				flex={1}
				alignContent='flex-start'
				columnSpacing='24px'
				rowSpacing='12px'
			>
				<Grid
					item
					xs={6}
					display='flex'
					flexDirection='row'
					justifyContent='flex-start'
					alignItems='center'
				>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='flex-start'
						alignItems='center'
						paddingX='40px'
					>
						<Typography
							color='primary'
							fontSize='16px'
							fontWeight='700'
							lineHeight='24px'
							letterSpacing='0.5px'
							textTransform='initial'
						>
							Filtros
						</Typography>
						<IconButton size='small' onClick={() => setShowFilter(!showFilter)}>
							{showFilter ? (
								<ExpandLessIcon fontSize='small' color='action' />
							) : (
								<ExpandMoreIcon fontSize='small' color='action' />
							)}
						</IconButton>
					</Box>
				</Grid>
				<Grid item xs={6} display='flex' justifyContent='flex-end' alignItems='center'>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='flex-start'
						alignItems='center'
						paddingX='40px'
					>
						<Button
							disabled={filteredOrders.length === 0}
							variant='contained'
							color='secondary'
							disableRipple
							disableElevation
							onClick={onOpenReportConfirmModal}
						>
							<Typography
								color={filteredOrders.length === 0 ? 'inherited' : 'white'}
								fontSize='16px'
								fontWeight='700'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								Exportar relatório
							</Typography>
						</Button>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Collapse in={showFilter}>
						<ReportFilter
							clientsEntities={clients}
							devicesEntities={devices}
							onFilter={filter}
						/>
					</Collapse>
				</Grid>

				<Grid item xs={6} height='65px'>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='flex-start'
						alignItems='center'
						paddingX='40px'
					>
						{filteredOrders.length === 1 ? (
							<Typography
								color='#BDBDBD'
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								0 eventos foram encontrados
							</Typography>
						) : filteredOrders.length === 1 ? (
							<Typography
								color='#BDBDBD'
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								<span
									style={{
										fontWeight: 400,
										color: theme.palette.secondary.main,
									}}
								>
									1
								</span>{' '}
								evento foi encontrado
							</Typography>
						) : (
							<Typography
								color='#BDBDBD'
								fontSize='16px'
								fontWeight='400'
								lineHeight='24px'
								letterSpacing='0.5px'
								textTransform='initial'
							>
								<span
									style={{
										fontWeight: 400,
										color: theme.palette.secondary.main,
									}}
								>
									{filteredOrders.length}
								</span>{' '}
								eventos foram encontrados
							</Typography>
						)}
					</Box>
				</Grid>
				<Grid item xs={6} height='65px'>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='flex-start'
						alignItems='center'
						paddingX='40px'
					>
						<TextField
							variant='outlined'
							fullWidth
							size='small'
							label=''
							helperText={null}
							placeholder=' Pesquisar'
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setSearch(event.target.value)
							}
							InputProps={{
								startAdornment: <SearchIcon fontSize='small' color='secondary' />,
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Box
						display='flex'
						flex={1}
						flexDirection='column'
						alignItems='center'
						justifyContent='flex-start'
						paddingX='40px'
					>
						{/* {showFilter && analysesHook.loading && <LoadingDisplay />} */}

						{filteredOrders.length === 0 && (
							<Box
								display='flex'
								flexDirection='column'
								flex={1}
								alignItems='center'
								justifyContent='center'
								width='100%'
								minHeight='45vh'
							>
								<Typography
									color='primary'
									fontSize='20px'
									fontWeight='700'
									lineHeight='24px'
									letterSpacing='0.5px'
									textTransform='initial'
								>
									Escolha os filtros para visualizar o relatório desejado
								</Typography>
							</Box>
						)}

						{filteredOrders.length > 0 && (
							<Box
								display='flex'
								flexDirection='column'
								flex={1}
								alignItems='center'
								justifyContent='flex-start'
								width='100%'
							>
								{[...filteredOrders]
									.splice(
										// parseInt(analysesHook.entitiesPerRow) *
										// 	analysesHook.paginate,
										// parseInt(analysesHook.entitiesPerRow)
										parseInt(ordersHook.entitiesPerRow) * ordersHook.paginate,
										parseInt(ordersHook.entitiesPerRow)
									)
									.map((item, index) => {
										return (
											<Box paddingY='8px' width='80%'>
												<ReportItem
													item={item}
													index={index}
													onSelect={select}
													selectedAnalysisIds={selectedAnalysisIds}
												/>
											</Box>
										);
									})}
							</Box>
						)}

						{filteredOrders.length > 0 && (
							<Box
								display='flex'
								flexDirection='column'
								alignItems='center'
								width='100%'
								paddingTop='12px'
							>
								<Pagination
									count={Math.ceil(
										filteredOrders.length /
											// parseInt(analysesHook.entitiesPerRow)
											parseInt(ordersHook.entitiesPerRow)
									)}
									onChange={handlePaginationChange}
								/>
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>

			<ReportConfirmModal
				open={openReportConfirmModal}
				onClose={() => setOpenReportConfirmModal(false)}
				onConfirm={onReport}
			/>
		</Box>
	);
};

export default ReportsPage;
