import api, { APP_URL, KEYCLOAK_URL, KEYCLOAK_REALM } from ".";
import { IAuth, IAuthLogin, IAuthPasswordRecovery, IAuthResetPassword, IAuthUserLogged } from "../types";
import { parseStringToDate } from "../utils/dates";
import { EntityServices } from "./entities";


export class AuthenticationServices extends EntityServices<IAuth> {
  constructor () {
    const _baseUrl: string = "/api_manager/auth";
    super("Autenticação", _baseUrl)
  }

  async login(_email: string, _password: string): Promise<IAuthLogin | IAuthResetPassword> {
    const payload: any = {
      email: _email.toLowerCase(),
      username: _email.toLowerCase(),
      password: _password,
    };
    const response: any = await api.post(`${this.baseUrl}/login`, payload);
    if (response?.request && response.request?.status === 200) {
      if (response.data.action && response.data.action.indexOf("UPDATE_PASSWORD") >= 0)
        return this.convertApiResponseToIAuthResetPasword(response.data);
      return this.convertApiResponseToIAuthLogin(response.data);
    } else if (response?.request?.status === 401) {
      if (response.response?.data?.error === "Email or password invalid") throw new Error(response.response?.data?.error);
      if (response.response?.data?.error === "Inactive user") throw new Error(response.response?.data?.error);
    }
    throw new Error("Não foi possível efetuar o login.");
  }

  async refreshToken(_refreshToken: string) {
    const response: any = await api.post(`${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`, {
      refreshToken: _refreshToken,
    });
    if (response?.request && response.request?.status === 200) {
      if (response.data.action && response.data.action.indexOf("UPDATE_PASSWORD") >= 0)
        return this.convertApiResponseToIAuthResetPasword(response.data);
      return this.convertApiResponseToIAuthLogin(response.data);
    } else if (response?.request?.status === 401) {
      if (response.response?.data?.error === "Email or password invalid") throw new Error(response.response?.data?.error);
      if (response.response?.data?.error === "Inactive user") throw new Error(response.response?.data?.error);
    }
    return undefined;
  }

  async inactiveAuth(_email: string): Promise<IAuth> {
    const response: any = await api.put(`${this.baseUrl}/inactive/${_email}`);

    if (response?.request && response.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível obter o usuário logado.");
  }

  async getLoggedUser(): Promise<IAuthUserLogged> {
    const response: any = await api.get(`${this.baseUrl}/logged_user`);

    if (response?.request && response.request?.status === 200) {
      return this.convertToIAuthUserLogged(response.data);
    }
    throw new Error("Não foi possível obter o usuário logado.");
  }

  async forgotPassword(_email: string) {
    const payload: any = {
      email: _email,
      frontEndUrl: APP_URL,
    };
    const response: any = await api.post(`${this.baseUrl}/forgot_password`, payload);
    if (response?.request && response.request?.status === 204) {
      return true;
    }
    return false;
  }

  async getPasswordRecovery(_token: string): Promise<IAuthPasswordRecovery> {
    const response: any = await api.get(`${this.baseUrl}/forgot_password/${_token}`);

    if (response?.request && response.request?.status === 200) {
      return this.convertToIAuthPasswordRecovery(response.data);
    }
    throw new Error("Não foi possível obter o usuário logado.");
  }

  async putPasswordRecovery(_token: string, _password: string): Promise<IAuthLogin> {
    const response: any = await api.put(`${this.baseUrl}/forgot_password/${_token}`, {
      password: _password,
    });

    if (response?.request && response.request?.status === 200) {
      return this.convertApiResponseToIAuthLogin(response.data);
    }
    throw new Error("Não foi possível obter o usuário logado.");
  }

  updatePasswordWithKey = async (_key: string, _password: string) => {
    const response = await api
      .put(`${this.baseUrl}/forgot_password/${_key}`, {
        type: "password",
        value: _password,
        temporary: false,
      })
      .catch((_err: any) => {
        throw _err;
      });

    if (response && response.request) {
      const status = response.request.status;
      if (status === 200) return true;
      else if (status === 400 && (response as any).response && (response as any).response.data) {
        if ((response as any).response.data.error === "E-mail not found on database") throw new Error((response as any).response.data.error);
        throw new Error((response as any).response.data as string);
      }
    }

    return false;
  };

  convertApiResponseToIAuthLogin(_data: any): IAuthLogin {
    return {
      accessToken: _data.accessToken,
      expiresIn: _data.expiresIn ?? 0,
      refreshExpiresIn: _data.refreshExpiresIn,
      refreshToken: _data.refreshToken,
      tokenType: _data.tokenType,
      notBeforePolicy: _data.notBeforePolicy,
      sessionState: _data.sessionState,
      scope: _data.scope,
    };
  }

  convertApiResponseToIAuthResetPasword(_data: any): IAuthResetPassword {
    return {
      key: _data.key,
      action: _data.action,
    };
  }

  convertToIAuthPasswordRecovery(_data: any): IAuthPasswordRecovery {
    return {
      id: _data.id,
      userId: _data.userId,
      token: _data.token,
      used: _data.used,
      frontEndUrl: _data.frontEndUrl,
      createdAt: parseStringToDate(_data.createdAt),
      updatedAt: parseStringToDate(_data.updatedAt),
    };
  }

  convertParsedTokenToIAuthUserLogged(_data: any): IAuthUserLogged {
    return {
      id: _data.sub,
    //   fullName: _data.name,
      firstName: _data.given_name,
      lastName: _data.family_name,
      email: _data.email,
    };
  }

  convertToIAuthUserLogged(_data: any): IAuthUserLogged {
    return {
      id: _data.id,
    //   fullName: _data.fullName,
      firstName: _data.firstName,
      lastName: _data.lastName,
      email: _data.email,
    };
  }
}
