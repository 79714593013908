import { FC, useEffect, useState } from 'react';

import { useUsers } from 'src/hooks/users';
import { useRoles } from 'src/hooks/roles';
import { DataTable, InsertButton } from 'src/components';
import { IManagementPage } from 'src/types';

const userTemplate = {
	id: '',
	username: '',
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	roleId: '',
};

const UsersManagement: FC<IManagementPage> = (params) => {
	const { entities, fetchEntities, createNewEntity, editEntity, deleteEntity } = useUsers();

	const { fetchGroups } = useRoles();
	const [entity, setEntity] = useState<any>(entities[0]);

	const formatUserFields = async () => {
		const tempGroups = await fetchGroups();

		const tempEntity: any = {};
		Object.assign(tempEntity, entities[0]);
		tempEntity.access = tempGroups;

		setEntity(tempEntity);
	};

	useEffect(() => {
		fetchEntities();
	}, []);

	useEffect(() => {
		formatUserFields();
	}, [entities[0]]);

	return (
		<div className='container flex column px-2'>
			<InsertButton item={entity ?? userTemplate} service={createNewEntity} />
			<DataTable
				entityTemplate={entity}
				entities={entities}
				editEntity={editEntity}
				deleteEntity={deleteEntity}
				setPage={params.setPage}
			/>
		</div>
	);
};

export default UsersManagement;
