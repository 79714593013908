import moment from "moment";

export const DATE_FORMAT: string = "DD/MM/YYYY";
export const TIME_FORMAT: string = "HH:mm:ss";
export const DATE_TIME_FORMAT: string = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const ISO_DATE_FORMAT: string = "YYYY-MM-DD";
export const ISO_DATE_TIME_FORMAT: string = `${ISO_DATE_FORMAT} ${TIME_FORMAT}`;

export const parseStringToDate = (_dateStr: string, _format: string = "YYYY-MM-DD HH:mm:ss.ms") => {
  const date: Date = moment(_dateStr, _format).toDate();
  return date;
};

export const parseDateToString = (_date: Date, _format: string = "DD/MM/YYYY HH:mm:ss.ms") => {
  const dateStr: string = moment(_date).format(_format);
  return dateStr;
};
