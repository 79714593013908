import { FC, createContext, useCallback, useState, useContext, useEffect, useMemo } from 'react';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import keycloakClientGenerator from '../services/keycloak';

import { AuthenticationServices } from 'src/services/auth';
import {
	IAuthContext,
	IAuthLogin,
	IAuthPasswordRecovery,
	IAuthUserLogged,
	IHookProvider,
	// instanceOfIAuthLogin,
} from 'src/types';
import { parseJwt } from 'src/utils/parsers';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const authenticationServices = useMemo(() => new AuthenticationServices(), []);

	const [keycloak, setKeycloak] = useState<Keycloak | undefined>(undefined);
	const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
	const [refreshToken, setRefreshToken] = useState<string | undefined>(undefined);
	const [expiresIn, setExpiresIn] = useState<number | undefined>(undefined);

	const [user, setUser] = useState<IAuthUserLogged | undefined>(undefined);
	const [routes] = useState<string[]>([]);
	const [clientId] = useState<string | undefined>(undefined);
	const [groups, setGroups] = useState<string[]>([]);
	const [roles, setRoles] = useState<string[]>([]);

	const postLogin = useCallback(
		async (_authLogin: IAuthLogin) => {
			setAccessToken(_authLogin.accessToken);
			setRefreshToken(_authLogin.refreshToken);
			setExpiresIn(_authLogin.expiresIn);

			const parsedToken: any | null = parseJwt(_authLogin.accessToken);
			// console.log('postLogin', parsedToken);

			if (parsedToken) {
				setGroups(parsedToken.groups ?? []);
				saveRoles(parsedToken.resource_access);
				const user =
					authenticationServices.convertParsedTokenToIAuthUserLogged(parsedToken);
				setUser(user);
			}
		},
		[authenticationServices]
	);

	const keycloakInit = async () => {
		if (keycloak) {
			return;
		}

		const keycloakClient = keycloakClientGenerator();
		const keycloakInitOptions: KeycloakInitOptions = {
			onLoad: 'login-required',
			checkLoginIframe: false,
		};

		try {
			await keycloakClient.init(keycloakInitOptions);
		} catch (error: any) {
			console.error('Failed to initialize adapter:', error);
			return;
		}

		if (keycloakClient.authenticated) {
			const authLogin: IAuthLogin = {
				accessToken: keycloakClient.token ?? '',
				expiresIn: keycloakClient.tokenParsed?.exp ?? 0,
				refreshExpiresIn: keycloakClient.refreshTokenParsed?.exp ?? 0,
				refreshToken: keycloakClient.refreshToken ?? '',
				tokenType: '',
				notBeforePolicy: 0,
				sessionState: '',
				scope: '',
			};

			await postLogin(authLogin);
		}
		setKeycloak(keycloakClient);
	};

	const saveRoles = (_resourceAccess: any) => {
		const allRoles = [];

		if (_resourceAccess.account) allRoles.push(..._resourceAccess.account.roles);
		else if (_resourceAccess.broker) allRoles.push(..._resourceAccess.broker.roles);
		else if (_resourceAccess.realm_management)
			allRoles.push(..._resourceAccess.realm_management.roles);
		else if (_resourceAccess.web_app) allRoles.push(..._resourceAccess.web_app.roles);
		else if (_resourceAccess.web_platform) allRoles.push(..._resourceAccess.web_platform.roles);

		setRoles(allRoles);
	};

	const clearSession = () => {
		setAccessToken(undefined);
		setRefreshToken(undefined);
		setExpiresIn(undefined);
		setUser(undefined);
		setGroups([]);
		setRoles([]);
	};

	const logout = () => {
		clearSession();
		keycloak?.logout();
	};

	const forgotPassword = async (_email: string) => {
		try {
			const forgotPasswordCreated: boolean = await authenticationServices.forgotPassword(
				_email
			);
			return forgotPasswordCreated;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const getPasswordRecovery = async (_token: string) => {
		try {
			const passwordRecovery: IAuthPasswordRecovery =
				await authenticationServices.getPasswordRecovery(_token);
			return passwordRecovery;
		} catch (err) {
			console.log(err);
			throw err;
		}
	};

	const putPasswordRecovery = async (_token: string, _password: string) => {
		try {
			const authLogin: IAuthLogin = await authenticationServices.putPasswordRecovery(
				_token,
				_password
			);
			return authLogin;
		} catch (err) {
			console.log(err);
			throw err;
		}
	};

	const updateRecoveryPasswordKey = async (_key: string, _newPassword: string) => {
		try {
			await authenticationServices.updatePasswordWithKey(_key, _newPassword);
		} catch (_err) {
			throw _err;
		}
	};

	useEffect(() => {
		const interval = setInterval(async () => {
			if (keycloak) {
				const tokenRefreshed = await keycloak.updateToken(1000 * 60 * 5);
				if (tokenRefreshed)
					await postLogin({
						accessToken: keycloak.token ?? '',
						expiresIn: keycloak.tokenParsed?.exp ?? 0,
						refreshExpiresIn: keycloak.refreshTokenParsed?.exp ?? 0,
						refreshToken: keycloak.refreshToken ?? '',
						tokenType: '',
						notBeforePolicy: 0,
						sessionState: '',
						scope: '',
					});
			}
		}, 1000 * 60 * 4); // 4 minutes

		return () => clearInterval(interval);
	}, [keycloak, postLogin]);

	return (
		<AuthContext.Provider
			value={{
				keycloak,
				keycloakInit,

				user,
				accessToken,
				refreshToken,
				expiresIn,

				clientId,
				roles,
				groups,
				routes,

				postLogin,
				logout,
				forgotPassword,
				getPasswordRecovery,
				putPasswordRecovery,
				updateRecoveryPasswordKey,
			}}
		>
			{_params.children}
		</AuthContext.Provider>
	);
};

export function useAuth() {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	// const hasRights = (path: string): boolean => {
	// 	return !!(context.roles.indexOf(path) >= 0);
	// };

	// context.hasRights = hasRights;
	return context;
}
