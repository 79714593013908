import { AxiosResponse } from "axios";

import api from ".";
import { EntityServices } from "./entities";
import { IOrders, IOrderImages } from "src/types";

export class OrdersServices extends EntityServices<IOrders> {

    constructor () {
        const _baseUrl: string = "/os";
        super("Ordem de serviço", _baseUrl)
    }

    async getImages (_query?: string, osId?: string): Promise<IOrderImages[]> {
        const response: AxiosResponse = await api.get(`${this.baseUrl}/${osId}/images${_query ? _query : ''}`);

        if (response.data && response?.request?.status === 200) {
            return response.data;
        }
        throw new Error(`Não foi possível buscar as imagens da ${this.entityForLog}`);
    }

    async checkProgress (_id: string): Promise<boolean> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/${_id}/check_progress`);

    if (response?.request?.status === 200) {
        return response.data;
    }
    throw new Error(`Não foi possível verificar o status da ${this.entityForLog}`);
    }
}
