import { FC } from 'react';
import { AuthProvider } from './hooks/auth';
import RouterProvider from './routes';
import { ThemeProvider } from '@mui/material/styles';
// import { ResponsiveProvider } from "./hooks/responsive";
import Theme from './components/theme';

import './css/App.css';

/*
Applies theme and responsiveness related atributes and functionalities to Router component
*/
const App: FC = () => {
	return (
		<AuthProvider>
			<ThemeProvider theme={Theme}>
				{/* <ResponsiveProvider> */}
				<RouterProvider />
				{/* </ResponsiveProvider> */}
			</ThemeProvider>
		</AuthProvider>
	);
};

export default App;
