import { FC, createContext, useContext, useState } from 'react';
import moment from 'moment';

import { OrdersServices } from 'src/services/ordersOfService';
import { ReportServices } from 'src/services/reports';
import {
	IOrders,
	IOrderImages,
	IOrdersContext,
	IHookProvider,
	IReportGenerate,
	IReportFilterParams,
} from 'src/types';

/*
Creates a new context using a given interface as type
*/
const OrdersContext = createContext<IOrdersContext>({} as IOrdersContext);

/**
Applies state and CRUD functionalities for OS objects,
providing them to child components
@provider
*/
export const OrdersProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const orderServices = new OrdersServices();

	const [entities, setOrders] = useState<IOrders[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchEntity, setSearchEntity] = useState<string>('');
	const [entitiesPerRow, setEntitiesPerRow] = useState<string>('8');
	const [paginate, setPaginate] = useState<number>(0);

	const createNewEntity = async (_os: IOrders) => {
		try {
			const os = await orderServices.createEntity(_os);

			fetchEntities();
			return os;
		} catch (_err) {
			throw _err;
		}
	};

	const editEntity = async (_id: string, _os: IOrders) => {
		try {
			const os = await orderServices.updateEntity(_id, _os);

			fetchEntities();
			return os;
		} catch (_err) {
			throw _err;
		}
	};

	const deleteEntity = async (_id: string) => {
		try {
			const os = await orderServices.deleteEntity(_id);

			fetchEntities();
			return os;
		} catch (_err) {
			throw _err;
		}
	};

	const fetchAll = async (_query?: string) => {
		let orders: IOrders[] = [];
		setLoading(true);
		try {
			orders = await orderServices.getAll(_query);
		} catch (_err) {
			console.log(_err);
		} finally {
			setOrders([...orders]);
			setLoading(false);
		}
	};

	const fetchEntities = async (_query?: string) => {
		let orders: IOrders[] = [];
		setLoading(true);
		try {
			orders = await orderServices.getEntities(_query);
		} catch (_err) {
			console.log(_err);
		} finally {
			setOrders([...orders]);
			setLoading(false);
			return orders;
		}
	};

	const fetchEntity = async (_id: string) => {
		const os: IOrders = await orderServices.getEntity(_id);
		return os;
	};

	const fetchImages = async (_query?: string, osId?: string) => {
		let osImages: IOrderImages[] = [];
		try {
			osImages = await orderServices.getImages(_query, osId);
		} catch (_err) {
			console.log(_err);
		} finally {
			return osImages;
		}
	};

	const clearOrders = () => {
		setOrders([]);
	};

	const checkOSProgress = async (_id: string) => {
		return await orderServices.checkProgress(_id);
	};

	const generateReport = async (_payload: IReportGenerate, _query?: string) => {
		const reportServices = new ReportServices();
		const report: any = await reportServices.generateReport(_payload, _query);

		if (report) {
			const url = window.URL.createObjectURL(new Blob([report]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				'relatorio_' + moment(Date.now()).format('YYYY_MM_DD_HH_mm_ss') + '.pdf'
			); // or any other extension
			document.body.appendChild(link);
			link.click();
		}
	};

	const createQuery = (params: IReportFilterParams) => {
		let query: string = '';
		if (params.startDate)
			query += `&start_date=${moment(params.startDate?.toString()).format('YYYY-MM-DD')}`;
		if (params.endDate)
			query += `&end_date=${moment(params.endDate?.toString()).format('YYYY-MM-DD')}`;
		if (params.startTime)
			query += `&start_time=${moment(params.startTime?.toString()).format('HH:mm:ss')}`;
		if (params.endTime)
			query += `&end_time=${moment(params.endTime?.toString()).format('HH:mm:ss')}`;

		const deviceNames: string[] = params.deviceNames.filter((item) => item !== 'Todos');
		if (deviceNames.length > 0) query += `&device_names=${deviceNames.join(',')}`;

		const clientsNames: string[] = params.clientsNames.filter((item) => item !== 'Todos');
		if (clientsNames.length > 0) query += `&clients_names=${clientsNames.join(',')}`;

		if (params.analysisIsAiRight) query += `&analysis_is_ai_right=${params.analysisIsAiRight}`;

		if (params.orderByDesc) query += '&order_by_desc=1';
		if (params.orderByDateTime) query += '&order_by_datetime=1';
		if (params.orderById) query += '&order_by_id=1';
		if (params.orderByDetection) query += '&order_by_detection=1';
		if (params.orderByTags) query += '&order_by_tags=1';
		if (params.orderByDeviceName) query += '&order_by_device_name=1';
		if (params.orderByClientName) query += '&order_by_client_name=1';

		if (query.length > 0) query = '?' + query.slice(1);
		return query;
	};

	return (
		<OrdersContext.Provider
			value={{
				entities,
				loading,
				fetchAll,
				fetchEntities,
				fetchEntity,
				createNewEntity,
				editEntity,
				deleteEntity,
				fetchImages,
				clearOrders,
				checkOSProgress,
				createQuery,
				generateReport,

				searchEntity,
				setSearchEntity,
				entitiesPerRow,
				setEntitiesPerRow,
				paginate,
				setPaginate,
			}}
		>
			{_params.children}
		</OrdersContext.Provider>
	);
};

/*
Calls current context to be used in child component
*/
export function useOS() {
	const context = useContext(OrdersContext);

	if (!context) {
		throw new Error('useOrders must be used within an OrdersProvider');
	}

	return context;
}
